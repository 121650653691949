import React, {useState, useEffect} from 'react';
import './shared/styles/backgrounds.css';
import './shared/styles/components.css';
import './shared/styles/dimensions.css';
import './shared/styles/displays.css';
import './shared/styles/margin.css';
import './shared/styles/padding.css';
import './shared/styles/positions.css';
import './shared/styles/overflow.css';
import './shared/styles/opacity.css';
import './shared/styles/borders.css';
import './shared/styles/shadows.css';
import './shared/styles/border-radius.css';
import './shared/styles/text.css';
import './shared/styles/transitions.css';
import './shared/styles/pointers.css';
import './shared/styles/selection.css';
import './shared/styles/animations.css';

import firebase from 'firebase/app';
import 'firebase/auth';

import {Switch, Route, Redirect, useLocation} from "react-router";

import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import {AppCombinedStates, AppRoute, FirebaseAppParameters} from "./AppDefinitions";
import {setFirebaseAppParameters, setAppRoute, setUserAuthData, setAppOnline, setAppOffline} from "./AppActions";
import {isCurrentRoutingLoginPage, isCurrentRoutingPublic} from './shared/ts/generalTools';
import {getImportantLoggedUserData} from "./shared/ts/userDataTools";

import loadable from '@loadable/component';
import PasswordLoader from "./shared/components/PasswordLoader/PasswordLoader";
import {useEffectOnce} from "react-use";



const PublicComponent = loadable(() => import('./Public/Public'));
const AuthComponent = loadable(() => import('./Auth/Auth'));
const TestComponent = loadable(() => import('../src/Auth/Test'));

let firebaseAuthRef: firebase.auth.Auth;
let previousLocation: AppRoute | null = null;


function App(props: any) {
	const location: AppRoute = useLocation();
	useEffect(
		() => {
			if (previousLocation !== null && (previousLocation.pathname !== location.pathname)) {
				window.scrollTo(0, 0);
			}

			props.setAppRoute(location);
			previousLocation = location;
		}
	);

	const [pendingRequests, setPendingRequests]: [number, Function] = useState(1);
	useEffect(
		() => {
			setPendingRequests(props.pendingRequests);
		},
		[props.pendingRequests]
	);

	const [isProd, setIsProd]: [boolean, Function] = useState(props.isProd);
	useEffect(
		() => {
			setIsProd(props.isProd);
		},
		[props.isProd]
	);

	const [wasInitialAuthChecked, setWasInitialAuthChecked]: [boolean, Function] = useState(false);
	useEffect(
		() => {
			setWasInitialAuthChecked(props.wasInitialAuthChecked);
		},
		[props.wasInitialAuthChecked]
	);

	const [authUserData, setAuthUserData]: [firebase.User | null, Function] = useState(null);
	useEffect(
		() => {
			setAuthUserData(props.authUserData);
		},
		[props.authUserData]
	);

	useEffectOnce(
		() => {
			let firebaseAppParameters: FirebaseAppParameters | null = null;


			// For Firebase JS SDK v7.20.0 and later, measurementId is optional
			// if (isProd) {
			if (true) {
				firebaseAppParameters = {
					apiKey: "AIzaSyCsax3tF0rx9qNhJXCKTxBA58X40gl4SCg",
					authDomain: "painless-passwords-prod.firebaseapp.com",
					projectId: "painless-passwords-prod",
					storageBucket: "painless-passwords-prod.appspot.com",
					messagingSenderId: "534682132678",
					appId: "1:534682132678:web:4e57251c274a9cbd7737c7",
					measurementId: "G-B30P5ZKZBR",
				};

			} else {
				firebaseAppParameters = {
					apiKey: "AIzaSyBX9wcAiGRr9fsOM35_1yoDuOB6a1ucsSU",
					authDomain: "painless-passwords-local.firebaseapp.com",
					projectId: "painless-passwords-local",
					storageBucket: "painless-passwords-local.appspot.com",
					messagingSenderId: "925813778025",
					appId: "1:925813778025:web:bbcd47919f1624d690e4f8",
					measurementId: "G-RN0M72Z5E8",
				};
			}

			// initialize firebase app
			if (firebase.apps.length === 0) {
				firebase.initializeApp(firebaseAppParameters);
			}

			// set firebase app parameters in redux
			props.setFirebaseAppParameters(firebaseAppParameters);

			// get firebase auth reference
			firebaseAuthRef = firebase.auth();

			// handle changes in authentication
			firebaseAuthRef.onAuthStateChanged(onAuthStateChangeHandler);
			window.addEventListener('online', onlineChangeHandler);
			window.addEventListener('offline', offlineChangeHandler);
		}
	);

	function onAuthStateChangeHandler(user: firebase.User | null): void {
		// validate user
		if (user !== null) {
			props.setUserAuthData(
				getImportantLoggedUserData(user)
			);

		} else {
			props.setUserAuthData(null);
		}
	}

	function getLoggedInRedirect(): React.ReactElement {
		// ignore if initial auth checked is not done
		if (!wasInitialAuthChecked) {
			return <div />;
		}

		// check if logged in user is in the login page
		if (authUserData !== null && isCurrentRoutingLoginPage()) {
			return <Redirect to={'/passwords/list'}/>;
		}

		return <div />
	}

	function getLoggedOutRedirect(): React.ReactElement {
		// ignore if initial auth checked is not done
		if (!wasInitialAuthChecked) {
			return <div />;
		}

		// check if not logged in user is in users page
		if (authUserData === null && !isCurrentRoutingPublic()) {
			return <Redirect to={'/public/login'} />;
		}

		return <div />
	}

	function getInitialRoutes(): React.ReactElement {
		// don't do any logic until the user auth status was checked
		if (!wasInitialAuthChecked) {
			return <div />;
		}

		return (
			<Switch>
				<Route path={'/public'} component={PublicComponent} />
				<Route path={['/passwords', '/creditcards', '/profile', '/admin']} component={AuthComponent} />

				<Route path={'/test'} component={TestComponent} />

				{/* === TODO: Add this route to the home page === */}
				<Route path={'/'}>
					{authUserData === null ? <Redirect to={'/public/login'} /> : <Redirect to={'/passwords/list'} />}
				</Route>
			</Switch>
		);
	}



	function onlineChangeHandler(): void {
		props.setAppOnline();
	}

	function offlineChangeHandler(): void {
		props.setAppOffline();
	}



	return (
		<div className={'App gray-background full-width min-full-viewport-height'}>
			{/* === GET REDIRECT WHEN USER LOGS IN === */}
			{getLoggedInRedirect()}


			{/* === REDIRECT USER TO LOG IN PAGE WHEN USER LOGS OUT === */}
			{getLoggedOutRedirect()}


			{/* === INITIAL ROUTES === */}
			{getInitialRoutes()}


			{/* === LOADER === */}
			<PasswordLoader
				className={'opaque-white-background'}
				animate
				isLoading={(!wasInitialAuthChecked || pendingRequests > 0)}
				size={600}
                zIndex={1000}
				style={{
					position: 'fixed',
				}} />
		</div>
	);
}



// export default App;
export default connect(
	(state: AppCombinedStates) => {
		return {
			pendingRequests: state.app.pendingRequests,
			isProd: state.app.isProd,
			wasInitialAuthChecked: state.app.wasInitialAuthChecked,
			authUserData: state.app.authUserData,
		}
	},
	(dispatch) => {
		return bindActionCreators({
			setAppOnline: setAppOnline,
			setAppOffline: setAppOffline,
			setFirebaseAppParameters: setFirebaseAppParameters,
			setAppRoute: setAppRoute,
			setUserAuthData: setUserAuthData,
		}, dispatch);
	}
)(App);

import {
	GET_USER_CREDIT_CARDS,
	GOT_USER_CREDIT_CARDS_SUCCESS,
	GOT_USER_CREDIT_CARDS_ERROR,

	DELETE_USER_CREDIT_CARD,
	DELETED_USER_CREDIT_CARD_SUCCESS,
	DELETED_USER_CREDIT_CARD_ERROR,

	CreditCardState,
} from "./CreditCardsDefinitions";



export default function CreditCardsReducer(state: any = getDefaultState(), action: any): any {
	let currentState: CreditCardState = Object.assign({}, state);


	switch(action.type) {
		/* === GET CREDIT CARDS === */
		case GET_USER_CREDIT_CARDS:
			currentState.pendingRequests += 1;
			break;

		case GOT_USER_CREDIT_CARDS_SUCCESS:
			currentState.pendingRequests -= 1;
			currentState.creditCardsList = action.creditCardsList;
			currentState.creditCards = action.creditCards;
			currentState.error = null;
			break;

		case GOT_USER_CREDIT_CARDS_ERROR:
			currentState.pendingRequests -= 1;
			currentState.creditCardsList = [];
			currentState.creditCards = null;
			currentState.error = action.error;
			break;


		/* === DELETE CREDIT CARD === */
		case DELETE_USER_CREDIT_CARD:
			currentState.pendingRequests += 1;
			currentState.isDeletingCreditCardUID = action.deletingCreditCardUID;
			break;

		case DELETED_USER_CREDIT_CARD_SUCCESS:
			currentState.pendingRequests -= 1;
			currentState.isDeletingCreditCardUID = null;
			break;

		case DELETED_USER_CREDIT_CARD_ERROR:
			currentState.pendingRequests -= 1;
			currentState.isDeletingCreditCardUID = null;
			break;


		default:
			return state;
	}

	// make sure pending requests are never negative
	if (currentState.pendingRequests < 0) {
		currentState.pendingRequests = 0;
	}

	return currentState;
}



function getDefaultState(): CreditCardState {
	return {
		pendingRequests: 0,

		creditCardsList: [],
		creditCards: null,

		isDeletingCreditCardUID: null,

		error: null,
	}
}

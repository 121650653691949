/* ================================================ PUBLIC METHODS ================================================ */
export function getDeviceUID(): string {
	return `${getCleanURLDomain()}|${window.navigator.userAgent.replace(/\//g, '|')}`;
	// return `${getCleanURLDomain()}|${window.screen.width}|${window.screen.height}`;
}

export function isCurrentRoutingPublic(): boolean {
	return (window.location.pathname.indexOf('/public') !== -1);
}

export function isCurrentRoutingLoginPage(): boolean {
	return (window.location.pathname.indexOf('/public/login') !== -1);
}

export function isSet(value: any): boolean {
	return (value !== void 0 && value !== null);
}

export function setDocumentTitle(title: string): void {
	document.title = title;
}

export function removeTrailingSpaces(string: string): string {
	// check for "trim" support
	if (typeof string.trim !== void 0) {
		return string.trim();
	}

	return string.replace(/^\s+|\s+$/g,'');
}

export function getRandomPassword(length: number, option: boolean) {
	// let pw = "";
	let arr: any = [];
	let isOpera: boolean = false;
	let chars: Array<any> = ["abcdefghijklmnopqrstuvwxyz", "1234567890", "!@#$%&*()_+}{:?-=}[;/.,]", "ABCDEFGHIJKLMNOPQRSTUVWXYZ"];
	let random: string = chars.join('');
	let onlyLetters: string = `${chars[0]} ${chars[3]}`;

	if (window.crypto && window.crypto.getRandomValues) {
		arr = new Uint32Array(length);
		window.crypto.getRandomValues(arr);

	} else if (navigator.userAgent.indexOf('Opera') > -1) {
		isOpera = true;
	} else {
		throw new Error("Your browser can't generate secure random numbers");
	}

	function randomNum(len: number, opt: boolean) {
		let res = "", m = "";

		!opt ? m = onlyLetters : m = random;

		for (let i = 0; i < len; i++) {
			isOpera ? res += m[Math.floor(Math.random() * m.length)] : res += m[arr[i] % m.length];
		}

		return res;
	}

	return randomNum(length, option);
}



/* ================================================ PRIVATE METHODS ================================================ */
function getCleanURLDomain () {
	let initialURL = window.location.origin;
	let portStartIndex = null;


	// remove protocol
	initialURL = initialURL.replace('http://', '');
	initialURL = initialURL.replace('https://', '');

	// remove port
	portStartIndex = initialURL.lastIndexOf(':');
	initialURL = initialURL.substring(0, (portStartIndex === -1 ? initialURL.length : portStartIndex));

	// remove first/last dots
	initialURL = initialURL.replace('.', '<');
	initialURL = initialURL.replace('.', '>');

	return initialURL;
}

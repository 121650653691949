import React from 'react';
import './PasswordLoader.css';
import {Box} from "@material-ui/core";

import PainLessPasswordIcon from '../PainlessPasswordIcon/PainlessPasswordIcon';



export interface GoogleGIconProps extends React.ComponentProps<any> {
	size?: number | string;
	isLoading: boolean;
	zIndex?: number | string;
}

export default function PasswordLoader(props: GoogleGIconProps) {
	return (
		<Box
			display={'flex'}
			flexDirection={'column'}
			justifyContent={'center'}
			alignItems={'center'}
			zIndex={props.zIndex || 'auto'}
			style={props.style}
			className={`PasswordLoader loader-overlay ${(props.isLoading) ? 'loading' : ''} ${props.className}`}>

			<PainLessPasswordIcon
				size={props.size || 100}
				animate />
		</Box>
	)
}

import firebase from 'firebase/app';
import 'firebase/analytics';



let firebaseAnalyticsRef: firebase.analytics.Analytics | null = null;


export function logEvent(eventName: string, eventParameters: any = null): void {
	setFirebaseRefs()
		.then(
			() => {
				// make sure analytics instance is set
				if (firebaseAnalyticsRef === null) {
					return;
				}

				// fire event
				return firebaseAnalyticsRef.logEvent(eventName, eventParameters);
			}
		);
}

function setFirebaseRefs(): Promise<any> {
	if (firebaseAnalyticsRef === null) {
		return firebase
			.analytics
			.isSupported()
			.then(
				(isSupported: boolean) => {
					if (isSupported) {
						firebaseAnalyticsRef = firebase.analytics();
					}
				}
			)

	}

	return Promise.resolve(true);
}

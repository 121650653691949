import {
	GET_LOGGED_USER_PASSWORDS,
	GET_LOGGED_USER_PASSWORDS_SUCCESS,
	GET_LOGGED_USER_PASSWORDS_ERROR,

	ADD_LOGGED_USER_PASSWORD,
	ADDED_LOGGED_USER_PASSWORD_SUCCESS,
	ADDED_LOGGED_USER_PASSWORD_ERROR,

	UPDATE_LOGGED_USER_PASSWORD,
	UPDATED_LOGGED_USER_PASSWORD_SUCCESS,
	UPDATED_LOGGED_USER_PASSWORD_ERROR,

	GET_LOGGED_USER_PASSWORD_UPDATES,
	GOT_LOGGED_USER_PASSWORD_UPDATES_SUCCESS,
	GOT_LOGGED_USER_PASSWORD_UPDATES_ERROR,

	DELETE_LOGGED_USER_PASSWORD,
	DELETED_LOGGED_USER_PASSWORD_SUCCESS,
	DELETED_LOGGED_USER_PASSWORD_ERROR,

	DECRYPT_LOGGED_USER_PASSWORD,
	DECRYPTED_LOGGED_USER_PASSWORD_SUCCESS,
	DECRYPTED_LOGGED_USER_PASSWORD_ERROR,
	REMOVE_LOGGED_USER_PASSWORD_DECRYPTION,

	PasswordsState,
	DecryptedPasswordsObject,
	DecryptingPasswordsUIDs,
} from "./PasswordsDefinitions";



export default function passwordsReducer(state: PasswordsState = getDefaultState(), action: any): PasswordsState {
	let currentState: PasswordsState = Object.assign({}, state);


	switch (action.type) {
		/* ============== ADD PASSWORD ============== */
		case ADD_LOGGED_USER_PASSWORD:
			currentState.pendingRequests += 1;
			break;

		case ADDED_LOGGED_USER_PASSWORD_SUCCESS:
			currentState.pendingRequests -= 1;
			currentState.error = null;
			break;

		case ADDED_LOGGED_USER_PASSWORD_ERROR:
			currentState.pendingRequests -= 1;
			currentState.error = action.error;
			break;


		/* ============== GET PASSWORDS ============== */
		case GET_LOGGED_USER_PASSWORDS:
			currentState.pendingRequests += 1;
			break;

		case GET_LOGGED_USER_PASSWORDS_SUCCESS:
			currentState.pendingRequests -= 1;
			currentState.userPasswords = action.passwords;
			currentState.userPasswordsList = action.passwordsList;
			currentState.error = null;
			break;

		case GET_LOGGED_USER_PASSWORDS_ERROR:
			currentState.pendingRequests -= 1;
			currentState.userPasswords = null;
			currentState.userPasswordsList = [];
			currentState.error = action.error;
			break;


		/* ============== GET PASSWORD UPDATES ============== */
		case GET_LOGGED_USER_PASSWORD_UPDATES:
			currentState.pendingRequests += 1;
			break;

		case GOT_LOGGED_USER_PASSWORD_UPDATES_SUCCESS:
			currentState.pendingRequests -= 1;
			currentState.passwordsUpdatesObject[action.passwordUID] = action.passwordsUpdatesList;
			currentState.error = null;
			break;

		case GOT_LOGGED_USER_PASSWORD_UPDATES_ERROR:
			currentState.pendingRequests -= 1;
			currentState.passwordsUpdatesObject[action.passwordUID] = [];
			currentState.error = action.error;
			break;


		/* ============== UPDATE PASSWORDS ============== */
		case UPDATE_LOGGED_USER_PASSWORD:
			currentState.pendingRequests += 1;
			break;

		case UPDATED_LOGGED_USER_PASSWORD_SUCCESS:
			currentState.pendingRequests -= 1;
			currentState.error = null;
			break;

		case UPDATED_LOGGED_USER_PASSWORD_ERROR:
			currentState.pendingRequests -= 1;
			currentState.error = action.error;
			break;


		/* ============== DELETE PASSWORD ============== */
		case DELETE_LOGGED_USER_PASSWORD:
			currentState.pendingRequests += 1;
			currentState.isDeletingPasswordUID = action.passwordUID;
			break;

		case DELETED_LOGGED_USER_PASSWORD_SUCCESS:
			currentState.pendingRequests -= 1;
			currentState.isDeletingPasswordUID = null;
			currentState.error = null;
			break;

		case DELETED_LOGGED_USER_PASSWORD_ERROR:
			currentState.pendingRequests -= 1;
			currentState.isDeletingPasswordUID = null;
			currentState.error = action.error;
			break;


		/* ============== DECRYPT/PASSWORD ============== */
		case DECRYPT_LOGGED_USER_PASSWORD:
			currentState.pendingRequests += 1;

			// add password from decrypting passwords UIDs
			currentState.decryptingPasswordsUIDs = addRemoveDecryptingPassword(currentState.decryptingPasswordsUIDs, action.passwordUID, 'add');
			break;

		case DECRYPTED_LOGGED_USER_PASSWORD_SUCCESS:
			currentState.pendingRequests -= 1;

			// remove password from decrypting passwords UIDs
			currentState.decryptingPasswordsUIDs = addRemoveDecryptingPassword(currentState.decryptingPasswordsUIDs, action.passwordUID, 'remove');

			// add password string
			currentState.decryptedPasswords[action.passwordUID] = {
				passwordString: action.passwordString,
			}

			// add error
			currentState.decryptingPasswordError = null;
			break;

		case DECRYPTED_LOGGED_USER_PASSWORD_ERROR:
			currentState.pendingRequests -= 1;

			// remove password from decrypting passwords UIDs
			currentState.decryptingPasswordsUIDs = addRemoveDecryptingPassword(currentState.decryptingPasswordsUIDs, action.passwordUID, 'remove');

			// add error
			currentState.decryptingPasswordError = action.error;
			break;

		case REMOVE_LOGGED_USER_PASSWORD_DECRYPTION:
			currentState.decryptedPasswords = removeDecryptedPassword(currentState.decryptedPasswords, action.passwordUID);
			break;


		default:
			return state;
	}

	// make sure pendingRequests is not less than 0
	if (currentState.pendingRequests < 0) {
		currentState.pendingRequests = 0;
	}

	return currentState;
}



function getDefaultState(): PasswordsState {
	return {
		pendingRequests: 0,
		// availableCompanyLogos: null,
		// availableCompanyLogosList: [],
		// availableCompanyNameKeys: null,

		newPasswordUID: null,
		userPasswordsList: [],
		userPasswords: null,

		decryptedPasswords: {},
		decryptingPasswordsUIDs: {},
		decryptingPasswordError: null,

		passwordsUpdatesObject: {},

		isDeletingPasswordUID: null,

		sharedPasswordsList: [],
		sharedPasswords: null,

		error: null,
	}
}

function addRemoveDecryptingPassword(decryptingPasswordsUIDs: DecryptingPasswordsUIDs, passwordUID: string, action: 'add' | 'remove'): DecryptingPasswordsUIDs {
	const decryptingPasswordsUIDsClone: DecryptingPasswordsUIDs = Object.assign({}, decryptingPasswordsUIDs);


	switch(action) {
		case 'add':
			decryptingPasswordsUIDsClone[passwordUID] = true;
			break;

		case 'remove':
			delete decryptingPasswordsUIDsClone[passwordUID];
			break;

		default:
			break;
	}

	return decryptingPasswordsUIDsClone;
}

function removeDecryptedPassword(decryptedPasswords: DecryptedPasswordsObject, passwordUID: string): DecryptedPasswordsObject {
	const decryptedPasswordsClone: DecryptedPasswordsObject = Object.assign({}, decryptedPasswords);


	delete decryptedPasswordsClone[passwordUID];
	return decryptedPasswordsClone;
}

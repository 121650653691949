import firebase from "firebase/app";
import 'firebase/auth';



export function getImportantLoggedUserData(loggedUser: firebase.User | null): any | null {
	// validate loggedUser
	if (loggedUser === void 0 || loggedUser === null) {
		return null;
	}

	return {
		uid: loggedUser.uid,
		displayName: loggedUser.displayName,
		email: loggedUser.email,
		emailVerified: loggedUser.emailVerified,
		phoneNumber: loggedUser.phoneNumber,
		photoURL: loggedUser.photoURL,
	}
}

/* ============================================= ACTION NAMES ============================================= */
import {LoggedUserDataState} from "./Auth/LoggedUserDataDefinitions";
import {AvailableCompanyLogosState} from "./Auth/AvailableCompanyLogos/AvailableCompanyLogosDefinitions";
import {PasswordsState} from "./Auth/Passwords/PasswordsDefinitions";
import {CreditCardState} from "./Auth/CreditCards/CreditCardsDefinitions";
import {AvailableCreditCardIssuersState} from "./Auth/AvailableCreditCardIssuer/AvailableCreditCardIssuersDefinitions";

export const SET_FIREBASE_APP_PARAMETERS: string = '[APP] SET_FIREBASE_APP_PARAMETERS';

export const SET_APP_ONLINE = '[APP] SET_APP_ONLINE';
export const SET_APP_OFFLINE = '[APP] SET_APP_OFFLINE';

export const ADD_PENDING_REQUESTS: string = '[APP] ADD_PENDING_REQUESTS';
export const REMOVE_PENDING_REQUESTS: string = '[APP] REMOVE_PENDING_REQUESTS';

export const SET_APP_ROUTE: string = '[APP] SET_APP_ROUTE';
export const SET_PAGE_VIEW: string = '[APP] SET_PAGE_VIEW';

export const SET_USER_AUTH_DATA: string = '[APP] SET_USER_AUTH_DATA';

export const LOG_IN_WITH_GOOGLE: string = '[APP] LOG_IN_WITH_GOOGLE';
export const LOGGED_IN_WITH_GOOGLE_SUCCESS: string = '[APP] LOGGED_IN_WITH_GOOGLE_SUCCESS';
export const LOGGED_IN_WITH_GOOGLE_ERROR: string = '[APP] LOGGED_IN_WITH_GOOGLE_ERROR';

export const LOG_OUT: string = '[APP] LOG_OUT';
export const LOGGED_OUT_SUCCESS: string = '[APP] LOGGED_OUT_SUCCESS';
export const LOGGED_OUT_ERROR: string = '[APP] LOGGED_OUT_ERROR';



/* ============================================= ANALYTICS EVENT NAMES ============================================= */
export const page_view: string = 'page_view';
export const log_in_with_google: string = 'log_in_with_google';
export const log_out: string = 'log_out';



/* ============================================= COMBINE STATES ============================================= */
export interface AppCombinedStates {
	app: AppState;
	loggedUserData: LoggedUserDataState;

	passwords: PasswordsState;
	creditCards: CreditCardState;

	availableCompanyLogos: AvailableCompanyLogosState;
	availableCreditCardIssuers: AvailableCreditCardIssuersState;
}



/* ============================================= STATE ============================================= */
export interface AppRoute {
	hash: string;
	key?: string | undefined;
	pathname: string;
	search: string;
	state: any;
}

export interface AuthUserData {
	uid: string;
	displayName: string;
	email: string;
	emailVerified: boolean;
	phoneNumber: string;
	photoURL: string;
}

export type FirebaseAppParameterName = 'apiKey' | 'authDomain' | 'projectId' | 'storageBucket' | 'messagingSenderId' | 'appId' | 'measurementId';
export interface FirebaseAppParameters {
	apiKey: string;
	authDomain: string;
	projectId: string;
	storageBucket: string;
	messagingSenderId: string;
	appId: string;
	measurementId: string;
}

export interface AppState {
	isAppOnline: boolean;
	deviceId: string;
	isDev: boolean;
	isStaging: boolean;
	isProd: boolean;
	firebaseParameters: FirebaseAppParameters | null;
	isWebAppInstalled: boolean;
	currentRoute: AppRoute | null;
	routesHistory: Array<AppRoute>;
	pendingRequests: number;
	wasInitialAuthChecked: boolean;
	authUserData: AuthUserData | null;
	authUserError: string | null;
}

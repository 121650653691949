/* ============================================= AVAILABLE COMPANY NAMES ============================================= */
export const ADD_PENDING_REQUEST: string = '[CREDIT_CARD_ISSUERS] ADD_PENDING_REQUEST';
export const REMOVE_PENDING_REQUEST: string = '[CREDIT_CARD_ISSUERS] REMOVE_PENDING_REQUEST';

export const ADD_CREDIT_CARD_ISSUER: string = '[CREDIT_CARD_ISSUERS] ADD_CREDIT_CARD_ISSUER';
export const ADDED_CREDIT_CARD_ISSUER_SUCCESS: string = '[CREDIT_CARD_ISSUERS] ADDED_CREDIT_CARD_ISSUER_SUCCESS';
export const ADDED_CREDIT_CARD_ISSUER_ERROR: string = '[CREDIT_CARD_ISSUERS] ADDED_CREDIT_CARD_ISSUER_ERROR';

export const GET_AVAILABLE_CREDIT_CARD_ISSUERS: string = '[CREDIT_CARD_ISSUERS] GET_AVAILABLE_CREDIT_CARD_ISSUERS';
export const GOT_AVAILABLE_CREDIT_CARD_ISSUERS_SUCCESS: string = '[CREDIT_CARD_ISSUERS] GOT_AVAILABLE_CREDIT_CARD_ISSUERS_SUCCESS';
export const GOT_AVAILABLE_CREDIT_CARD_ISSUERS_ERROR: string = '[CREDIT_CARD_ISSUERS] GOT_AVAILABLE_CREDIT_CARD_ISSUERS_ERROR';

export const DELETE_AVAILABLE_CREDIT_CARD_ISSUER: string = '[CREDIT_CARD_ISSUERS] DELETE_AVAILABLE_CREDIT_CARD_ISSUER';
export const DELETED_AVAILABLE_CREDIT_CARD_ISSUERS_SUCCESS: string = '[CREDIT_CARD_ISSUERS] DELETED_AVAILABLE_CREDIT_CARD_ISSUERS_SUCCESS';
export const DELETED_AVAILABLE_CREDIT_CARD_ISSUERS_ERROR: string = '[CREDIT_CARD_ISSUERS] DELETED_AVAILABLE_CREDIT_CARD_ISSUERS_ERROR';



/* ============================================= GENERAL ============================================= */
export const availableCreditCardIssuersCollectionName: string = 'creditCardIssuers';
export const availableCreditCardIssuerFilePrefix: string = 'available-credit-card-issuer-logo__';



/* ============================================= ANALYTICS EVENT NAMES ============================================= */
/* ============================================= STATE ============================================= */
/* === CREDIT CARD ISSUER === */
export interface AvailableCreditCardIssuer {
	uid?: string;
	imageURL?: string;
	fileName: string;
	label: string;
}

/* === CREDIT CARD ISSUERS OBJECT === */
export interface AvailableCreditCardIssuersObject {
	[AvailableCreditCardIssuerUID: string]: AvailableCreditCardIssuer;
}

export interface AvailableIssuerNamesKeys {
	[issuerName: string]: string;
}

/* === UNKNOWN CREDIT CARDS ISSUERS LOGO === */
// export type UnknownCreditCardIssuer = 'password' | 'creditcard';
// export interface UnknownCreditCardIssuerLogo {
// 	uid?: string;
// 	companyName: string;
// 	type: UnknownCreditCardIssuer;
// 	date: any;
// }

/* === UNKNOWN CREDIT CARD ISSUERS OBJECT === */
// export interface UnknownCreditCardIssuersObject {
// 	[unknownCreditCardIssuerUID: string]: UnknownCreditCardIssuerLogo;
// }

/* === DELETING UNKNOWN COMPANY LOGO UIDs OBJECT === */
// export interface DeletingUnknownCreditCardIssuersObject {
// 	[unknownCompanyLogoUID: string]: boolean;
// }

/* === FINAL STATE === */
export interface AvailableCreditCardIssuersState {
	pendingRequests: number;

	issuers: AvailableCreditCardIssuersObject | null;
	issuersList: Array<AvailableCreditCardIssuer>;
	issuersNamesKeyMap: AvailableIssuerNamesKeys | null;

	addCardIssuerError: string | null;
	getCardIssuersError: string | null;
	deleteCardIssuerError: string | null;



	// addCompanyLogoError: string | null;
	// deletingCompanyLogoError: string | null;
	// getCompanyLogosError: string | null;
	// companyLogos: AvailableCreditCardIssuersObject | null;
	// companyLogosList: Array<AvailableCreditCardIssuer>;
	// companyNameKeys: AvailableCreditCardIssuersNamesKeys | null;
	//
	// addUnknownCreditCardIssuerLogoError: string | null;
	// deleteUnknownCreditCardIssuerLogoError: string | null;
	// getUnknownCreditCardIssuerLogoError: string | null;
	// unknownCompanies: UnknownCreditCardIssuersObject | null;
	// unknownCompaniesList: Array<string>;
	// deletingUnknownCreditCardIssuerLogoUIDsObject: DeletingUnknownCreditCardIssuersObject | null;
	//
	// isDeletingCompanyLogoUID: string | null;
}

import Password from './classes/Password';
import PasswordUpdate from "./classes/PasswordUpdate";


/* ============================================= ACTION NAMES ============================================= */
/* === GENERAL === */
export const SET_USER_PASSWORD_DETAILS_VIEW: string = '[PASSWORDS] SET_USER_PASSWORD_DETAILS_VIEW';


/* === USER PASSWORDS === */
export const GET_LOGGED_USER_PASSWORDS: string = '[PASSWORDS] GET_LOGGED_USER_PASSWORDS';
export const GET_LOGGED_USER_PASSWORDS_SUCCESS: string = '[PASSWORDS] GET_LOGGED_USER_PASSWORDS_SUCCESS';
export const GET_LOGGED_USER_PASSWORDS_ERROR: string = '[PASSWORDS] GET_LOGGED_USER_PASSWORDS_ERROR';

export const ADD_LOGGED_USER_PASSWORD: string = '[PASSWORDS] ADD_LOGGED_USER_PASSWORD';
export const ADDED_LOGGED_USER_PASSWORD_SUCCESS: string = '[PASSWORDS] ADDED_LOGGED_USER_PASSWORD_SUCCESS';
export const ADDED_LOGGED_USER_PASSWORD_ERROR: string = '[PASSWORDS] ADDED_LOGGED_USER_PASSWORD_ERROR';

export const UPDATE_LOGGED_USER_PASSWORD: string = '[PASSWORDS] UPDATE_LOGGED_USER_PASSWORD';
export const UPDATED_LOGGED_USER_PASSWORD_SUCCESS: string = '[PASSWORDS] UPDATED_LOGGED_USER_PASSWORD_SUCCESS';
export const UPDATED_LOGGED_USER_PASSWORD_ERROR: string = '[PASSWORDS] UPDATED_LOGGED_USER_PASSWORD_ERROR';

export const GET_LOGGED_USER_PASSWORD_UPDATES: string = '[PASSWORDS] GET_LOGGED_USER_PASSWORD_UPDATES';
export const GOT_LOGGED_USER_PASSWORD_UPDATES_SUCCESS: string = '[PASSWORDS] GOT_LOGGED_USER_PASSWORD_UPDATES_SUCCESS';
export const GOT_LOGGED_USER_PASSWORD_UPDATES_ERROR: string = '[PASSWORDS] GOT_LOGGED_USER_PASSWORD_UPDATES_ERROR';

export const DELETE_LOGGED_USER_PASSWORD: string = '[PASSWORDS] DELETE_LOGGED_USER_PASSWORD';
export const DELETED_LOGGED_USER_PASSWORD_SUCCESS: string = '[PASSWORDS] DELETED_LOGGED_USER_PASSWORD_SUCCESS';
export const DELETED_LOGGED_USER_PASSWORD_ERROR: string = '[PASSWORDS] DELETED_LOGGED_USER_PASSWORD_ERROR';

export const DECRYPT_LOGGED_USER_PASSWORD: string = '[PASSWORDS] DECRYPT_LOGGED_USER_PASSWORD';
export const DECRYPTED_LOGGED_USER_PASSWORD_SUCCESS: string = '[PASSWORDS] DECRYPTED_LOGGED_USER_PASSWORD_SUCCESS';
export const DECRYPTED_LOGGED_USER_PASSWORD_ERROR: string = '[PASSWORDS] DECRYPTED_LOGGED_USER_PASSWORD_ERROR';

export const REMOVE_LOGGED_USER_PASSWORD_DECRYPTION: string = '[PASSWORDS] REMOVE_LOGGED_USER_PASSWORD_DECRYPTION';
export const REMOVE_LOGGED_USER_PASSWORD_DECRYPTION_SUCCESS: string = '[PASSWORDS] REMOVE_LOGGED_USER_PASSWORD_DECRYPTION_SUCCESS';
export const REMOVE_LOGGED_USER_PASSWORD_DECRYPTION_ERROR: string = '[PASSWORDS] REMOVE_LOGGED_USER_PASSWORD_DECRYPTION_ERROR';



/* ============================================= ANALYTICS EVENT NAMES ============================================= */
export const user_password_details_viewed: string = 'user_password_details_viewed';
export const user_password_added: string = 'user_password_added';
export const user_password_updated: string = 'user_password_updated';
export const user_password_deleted: string = 'user_password_deleted';
export const got_user_passwords_list: string = 'got_user_passwords_list';



/* ============================================= STATE ============================================= */
/* === USER PASSWORDS === */
export interface UserPasswordsObject {
	[passwordUID: string]: Password;
}

export interface PasswordsUpdatesObject {
	[passwordUID: string]: Array<PasswordUpdate>;
}

export interface UserSharedPasswordsObject {
	[passwordUID: string]: Password;
}

export interface DecryptedPasswordsObject {
	[passwordUID: string]: DecryptedPassword;
}

export interface DecryptingPasswordsUIDs {
	[passwordsUIDs: string]: boolean;
}

export interface DecryptedPassword {
	passwordString: string;
}


/* === FINAL STATE === */
export interface PasswordsState {
	pendingRequests: number;

	newPasswordUID: string | null;
	userPasswordsList: Array<string>;
	userPasswords: UserPasswordsObject | null;

	decryptedPasswords: DecryptedPasswordsObject;
	decryptingPasswordsUIDs: DecryptingPasswordsUIDs;
	decryptingPasswordError: string | null;

	passwordsUpdatesObject: PasswordsUpdatesObject;

	isDeletingPasswordUID: string | null;

	sharedPasswordsList: Array<Password>;
	sharedPasswords: UserSharedPasswordsObject | null;

	error: string | null;
}

import React, {useState} from 'react';
import './PainLessPasswordIcon.css';
import {Box} from "@material-ui/core";



export interface PainLessPasswordIconProps extends React.ComponentProps<any> {
	size?: number | string;
	animate?: boolean;
}

export default function PainLessPasswordIcon(props: PainLessPasswordIconProps) {
	const [isAnimating, setIsAnimating]: [boolean, Function] = useState(Boolean(props.animate));


	function toggleAnimationHandler(): void {
		setIsAnimating(!isAnimating);
	}


	return (
		<Box
			display={'flex'}
			flexDirection={'column'}
			justifyContent={'center'}
			alignItems={'center'}
			zIndex={props.zIndex || 'auto'}
			style={props.style}
			className={`PainlessPasswordIcon ${isAnimating ? 'animate' : ''} ${props.className}`}>
			<svg
				width={props.size || 100}
				viewBox="0 0 500 100"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				onClick={toggleAnimationHandler}
				style={{
					background: 'transparent',
					maxWidth: '80%',
					borderRadius: 8,
					boxShadow: 'rgb(0 0 0 / 20%) 0px 3px 5px -1px, rgb(0 0 0 / 14%) 0px 6px 10px 0px, rgb(0 0 0 / 12%) 0px 1px 18px 0px',
				}}>
				<rect fill="none" width="500" height="100"/>
				<rect fill="#FFFFFF" className="field" stroke="#607D8B" x="2" y="2" width="496" height="96" rx="6" strokeWidth="4"/>
				<path fill="#FFFFFF" className="lock-container" stroke="#607D8B" strokeWidth="4" d="M8 2H98V98H8C4.68629 98 2 95.3137 2 92V8C2 4.68629 4.68629 2 8 2Z"/>

				<path fill="#607D8B" className={'asterisk n-1'} d="M134.469 50.9219L120.312 46.7031L122.469 39.625L136.625 44.875L136.203 28.75H143.375L142.906 45.1094L156.828 39.9531L158.984 47.0781L144.594 51.3438L153.875 64.0469L148.062 68.4531L139.344 54.9531L130.906 68.125L125.047 63.8594L134.469 50.9219Z"/>
				<path fill="#607D8B" className={'asterisk n-2'} d="M186.469 50.9219L172.312 46.7031L174.469 39.625L188.625 44.875L188.203 28.75H195.375L194.906 45.1094L208.828 39.9531L210.984 47.0781L196.594 51.3438L205.875 64.0469L200.062 68.4531L191.344 54.9531L182.906 68.125L177.047 63.8594L186.469 50.9219Z"/>
				<path fill="#607D8B" className={'asterisk n-3'} d="M238.469 50.9219L224.312 46.7031L226.469 39.625L240.625 44.875L240.203 28.75H247.375L246.906 45.1094L260.828 39.9531L262.984 47.0781L248.594 51.3438L257.875 64.0469L252.062 68.4531L243.344 54.9531L234.906 68.125L229.047 63.8594L238.469 50.9219Z"/>
				<path fill="#607D8B" className={'asterisk n-4'} d="M290.469 50.9219L276.312 46.7031L278.469 39.625L292.625 44.875L292.203 28.75H299.375L298.906 45.1094L312.828 39.9531L314.984 47.0781L300.594 51.3438L309.875 64.0469L304.062 68.4531L295.344 54.9531L286.906 68.125L281.047 63.8594L290.469 50.9219Z"/>
				<path fill="#607D8B" className={'asterisk n-5'} d="M342.469 50.9219L328.312 46.7031L330.469 39.625L344.625 44.875L344.203 28.75H351.375L350.906 45.1094L364.828 39.9531L366.984 47.0781L352.594 51.3438L361.875 64.0469L356.062 68.4531L347.344 54.9531L338.906 68.125L333.047 63.8594L342.469 50.9219Z"/>
				<path fill="#607D8B" className={'asterisk n-6'} d="M394.469 50.9219L380.312 46.7031L382.469 39.625L396.625 44.875L396.203 28.75H403.375L402.906 45.1094L416.828 39.9531L418.984 47.0781L404.594 51.3438L413.875 64.0469L408.062 68.4531L399.344 54.9531L390.906 68.125L385.047 63.8594L394.469 50.9219Z"/>

				<path fill="#FF5723" className="lock" d="M69.625 36.6667H66.4375V30.1905C66.4375 21.2533 59.2975 14 50.5 14C41.7025 14 34.5625 21.2533 34.5625 30.1905V36.6667H31.375C27.8687 36.6667 25 39.581 25 43.1429V75.5238C25 79.0857 27.8687 82 31.375 82H69.625C73.1313 82 76 79.0857 76 75.5238V43.1429C76 39.581 73.1313 36.6667 69.625 36.6667ZM40.9375 30.1905C40.9375 24.8152 45.2088 20.4762 50.5 20.4762C55.7912 20.4762 60.0625 24.8152 60.0625 30.1905V36.6667H40.9375V30.1905ZM69.625 75.5238H31.375V43.1429H69.625V75.5238ZM50.5 65.8095C54.0063 65.8095 56.875 62.8952 56.875 59.3333C56.875 55.7714 54.0063 52.8571 50.5 52.8571C46.9937 52.8571 44.125 55.7714 44.125 59.3333C44.125 62.8952 46.9937 65.8095 50.5 65.8095Z"/>
				<line stroke="#607D8B" className="cursor" strokeWidth="4" x1="423" y1="18" x2="423" y2="82"/>
			</svg>
		</Box>
	)
}

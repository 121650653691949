import {AvailableCompany} from './AvailableCompany';



/* ============================================= AVAILABLE COMPANY NAMES ============================================= */
/* === GENERAL === */
export const ADD_PENDING_REQUEST: string = '[AVAILABLE_COMPANY_LOGOS] ADD_PENDING_REQUEST';
export const REMOVE_PENDING_REQUEST: string = '[AVAILABLE_COMPANY_LOGOS] REMOVE_PENDING_REQUEST';

/* === ADD === */
export const ADD_AVAILABLE_COMPANY_LOGO: string = '[AVAILABLE_COMPANY_LOGOS] ADD_AVAILABLE_COMPANY_LOGO';
export const ADDED_AVAILABLE_COMPANY_LOGO_SUCCESS: string = '[AVAILABLE_COMPANY_LOGOS] ADDED_AVAILABLE_COMPANY_LOGO_SUCCESS';
export const ADDED_AVAILABLE_COMPANY_LOGO_ERROR: string = '[AVAILABLE_COMPANY_LOGOS] ADDED_AVAILABLE_COMPANY_LOGO_ERROR';

/* === DELETE === */
export const DELETE_AVAILABLE_COMPANY_LOGO: string = '[AVAILABLE_COMPANY_LOGOS] DELETE_AVAILABLE_COMPANY_LOGO';
export const DELETED_AVAILABLE_COMPANY_LOGO_SUCCESS: string = '[AVAILABLE_COMPANY_LOGOS] DELETED_AVAILABLE_COMPANY_LOGO_SUCCESS';
export const DELETED_AVAILABLE_COMPANY_LOGO_ERROR: string = '[AVAILABLE_COMPANY_LOGOS] DELETED_AVAILABLE_COMPANY_LOGO_ERROR';

/* === GET === */
export const GET_AVAILABLE_COMPANY_LOGOS: string = '[AVAILABLE_COMPANY_LOGOS] GET_AVAILABLE_COMPANY_LOGOS';
export const GOT_AVAILABLE_COMPANY_LOGOS_SUCCESS: string = '[AVAILABLE_COMPANY_LOGOS] GOT_AVAILABLE_COMPANY_LOGOS_SUCCESS';
export const GOT_AVAILABLE_COMPANY_LOGOS_ERROR: string = '[AVAILABLE_COMPANY_LOGOS] GOT_AVAILABLE_COMPANY_LOGOS_ERROR';



/* ============================================= UNKNOWN COMPANY NAMES ============================================= */
/* === ADD === */
export const ADD_UNKNOWN_COMPANY_LOGO: string = '[AVAILABLE_COMPANY_LOGOS] ADD_UNKNOWN_COMPANY_LOGO';
export const ADDED_UNKNOWN_COMPANY_LOGO_SUCCESS: string = '[AVAILABLE_COMPANY_LOGOS] ADDED_UNKNOWN_COMPANY_LOGO_SUCCESS';
export const ADDED_UNKNOWN_COMPANY_LOGO_ERROR: string = '[AVAILABLE_COMPANY_LOGOS] ADDED_UNKNOWN_COMPANY_LOGO_ERROR';

/* === DELETE === */
export const DELETE_UNKNOWN_COMPANY_LOGO: string = '[AVAILABLE_COMPANY_LOGOS] DELETE_UNKNOWN_COMPANY_LOGO';
export const DELETED_UNKNOWN_COMPANY_LOGO_SUCCESS: string = '[AVAILABLE_COMPANY_LOGOS] DELETED_UNKNOWN_COMPANY_LOGO_SUCCESS';
export const DELETED_UNKNOWN_COMPANY_LOGO_ERROR: string = '[AVAILABLE_COMPANY_LOGOS] DELETED_UNKNOWN_COMPANY_LOGO_ERROR';

/* === GET === */
export const GET_UNKNOWN_COMPANY_LOGOS: string = '[AVAILABLE_COMPANY_LOGOS] GET_UNKNOWN_COMPANY_LOGOS';
export const GOT_UNKNOWN_COMPANY_LOGOS_SUCCESS: string = '[AVAILABLE_COMPANY_LOGOS] GOT_UNKNOWN_COMPANY_LOGOS_SUCCESS';
export const GOT_UNKNOWN_COMPANY_LOGOS_ERROR: string = '[AVAILABLE_COMPANY_LOGOS] GOT_UNKNOWN_COMPANY_LOGOS_ERROR';



/* ============================================= GENERAL ============================================= */
export const availableCompanyLogosCollectionName: string = 'companyLogos';
export const availableCompanyLogoFilePrefix: string = 'available-company-logo__';

export const unknownCompanyLogoCollectionName: string = 'unknownCompanyLogos';



/* ============================================= ANALYTICS EVENT NAMES ============================================= */
export const unknown_company_added: string = 'unknown_company_added';



/* ============================================= STATE ============================================= */
/* === COMPANY LOGO === */
// export interface AvailableCompany {
// 	uid?: string;
// 	imageURL?: string;
// 	fileName: string;
// 	label: string;
// }

/* === COMPANY LOGOS OBJECT === */
export interface AvailableCompanyLogosObject {
	[AvailableCompanyLogoUID: string]: AvailableCompany;
}

/* === COMPANY LOGOS NAMES KEYS === */
export interface AvailableCompanyLogosNamesKeys {
	[companyName: string]: string;
}

/* === UNKNOWN COMPANY LOGO === */
export type UnknownCompanyType = 'password' | 'creditcard';
export interface UnknownCompanyLogo {
	uid?: string;
	companyName: string;
	type: UnknownCompanyType;
	date: any;
}

/* === UNKNOWN COMPANY LOGOS OBJECT === */
export interface UnknownCompanyLogosObject {
	[unknownCompanyUID: string]: UnknownCompanyLogo;
}

/* === DELETING UNKNOWN COMPANY LOGO UIDs OBJECT === */
export interface DeletingUnknownCompanyLogosObject {
	[unknownCompanyLogoUID: string]: boolean;
}

/* === FINAL STATE === */
export interface AvailableCompanyLogosState {
	pendingRequests: number;

	addCompanyLogoError: string | null;
	deletingCompanyLogoError: string | null;
	getCompanyLogosError: string | null;
	companyLogos: AvailableCompanyLogosObject | null;
	companyLogosList: Array<string>;
	companyNameKeys: AvailableCompanyLogosNamesKeys | null;

	addUnknownCompanyLogoError: string | null;
	deleteUnknownCompanyLogoError: string | null;
	getUnknownCompanyLogoError: string | null;
	unknownCompanies: UnknownCompanyLogosObject | null;
	unknownCompaniesList: Array<string>;
	deletingUnknownCompanyLogoUIDsObject: DeletingUnknownCompanyLogosObject | null;

	isDeletingCompanyLogoUID: string | null;
}

import {
	/* === ADD === */
	ADD_PENDING_REQUEST,
	REMOVE_PENDING_REQUEST,


	/* === AVAILABLE COMPANIES === */
	/* === ADD === */
	ADD_AVAILABLE_COMPANY_LOGO,
	ADDED_AVAILABLE_COMPANY_LOGO_SUCCESS,
	ADDED_AVAILABLE_COMPANY_LOGO_ERROR,

	/* === DELETE === */
	DELETE_AVAILABLE_COMPANY_LOGO,
	DELETED_AVAILABLE_COMPANY_LOGO_SUCCESS,
	DELETED_AVAILABLE_COMPANY_LOGO_ERROR,

	/* === GET === */
	GET_AVAILABLE_COMPANY_LOGOS,
	GOT_AVAILABLE_COMPANY_LOGOS_SUCCESS,
	GOT_AVAILABLE_COMPANY_LOGOS_ERROR,


	/* === UNKNOWN COMPANIES === */
	/* === ADD === */
	ADD_UNKNOWN_COMPANY_LOGO,
	ADDED_UNKNOWN_COMPANY_LOGO_SUCCESS,
	ADDED_UNKNOWN_COMPANY_LOGO_ERROR,

	/* === DELETE === */
	DELETE_UNKNOWN_COMPANY_LOGO,
	DELETED_UNKNOWN_COMPANY_LOGO_SUCCESS,
	DELETED_UNKNOWN_COMPANY_LOGO_ERROR,

	/* === GET === */
	GET_UNKNOWN_COMPANY_LOGOS,
	GOT_UNKNOWN_COMPANY_LOGOS_SUCCESS,
	GOT_UNKNOWN_COMPANY_LOGOS_ERROR,

	/* === STATE === */
	AvailableCompanyLogosState,
} from "./AvailableCompanyLogosDefinitions";



export default function AvailableCompanyLogosReducer(state: AvailableCompanyLogosState = getDefaultState(), action: any): AvailableCompanyLogosState {
	const currentState: AvailableCompanyLogosState = Object.assign({}, state);


	switch(action.type) {
		/* === PENDING REQUESTS === */
		case ADD_PENDING_REQUEST:
			currentState.pendingRequests += 1;
			break;

		case REMOVE_PENDING_REQUEST:
			currentState.pendingRequests -= 1;
			break;


		/* === ADD AVAILABLE COMPANY === */
		case ADD_AVAILABLE_COMPANY_LOGO:
			currentState.pendingRequests += 1;
			currentState.addCompanyLogoError = null;
			break;

		case ADDED_AVAILABLE_COMPANY_LOGO_SUCCESS:
			currentState.pendingRequests -= 1;
			currentState.addCompanyLogoError = null;
			break;

		case ADDED_AVAILABLE_COMPANY_LOGO_ERROR:
			currentState.pendingRequests -= 1;
			currentState.addCompanyLogoError = action.error;
			break;


		/* === DELETE AVAILABLE COMPANY === */
		case DELETE_AVAILABLE_COMPANY_LOGO:
			currentState.pendingRequests += 1;
			currentState.isDeletingCompanyLogoUID = action.companyLogoUID;
			currentState.deletingCompanyLogoError = null;
			break;

		case DELETED_AVAILABLE_COMPANY_LOGO_SUCCESS:
			currentState.pendingRequests -= 1;
			currentState.isDeletingCompanyLogoUID = null;
			currentState.deletingCompanyLogoError = null;
			break;

		case DELETED_AVAILABLE_COMPANY_LOGO_ERROR:
			currentState.pendingRequests -= 1;
			currentState.isDeletingCompanyLogoUID = null;
			currentState.deletingCompanyLogoError = action.error;
			break;


		/* === GET AVAILABLE COMPANY === */
		case GET_AVAILABLE_COMPANY_LOGOS:
			currentState.pendingRequests += 1;
			break;

		case GOT_AVAILABLE_COMPANY_LOGOS_SUCCESS:
			currentState.pendingRequests -= 1;
			currentState.companyLogos = action.availableCompanyLogos;
			currentState.companyLogosList = action.availableCompanyLogosList;
			currentState.companyNameKeys = action.availableCompanyLogosNameKeys;
			currentState.getCompanyLogosError = null;
			break;

		case GOT_AVAILABLE_COMPANY_LOGOS_ERROR:
			currentState.pendingRequests -= 1;
			currentState.companyLogos = null;
			currentState.companyLogosList = [];
			currentState.companyNameKeys = null;
			currentState.getCompanyLogosError = action.error;
			break;


		/* === UNKNOWN COMPANIES === */
		/* === ADD === */
		case ADD_UNKNOWN_COMPANY_LOGO:
			currentState.pendingRequests += 1;
			break;

		case ADDED_UNKNOWN_COMPANY_LOGO_SUCCESS:
			currentState.pendingRequests -= 1;
			currentState.addUnknownCompanyLogoError = null;
			break;

		case ADDED_UNKNOWN_COMPANY_LOGO_ERROR:
			currentState.pendingRequests -= 1;
			currentState.addUnknownCompanyLogoError = action.error;
			break;

		/* === DELETE === */
		case DELETE_UNKNOWN_COMPANY_LOGO:
			currentState.pendingRequests += 1;
			currentState.deletingUnknownCompanyLogoUIDsObject = action.companyLogoUIDs;
			break;

		case DELETED_UNKNOWN_COMPANY_LOGO_SUCCESS:
			currentState.pendingRequests -= 1;
			currentState.deletingUnknownCompanyLogoUIDsObject = null;
			currentState.deleteUnknownCompanyLogoError = null;
			break;

		case DELETED_UNKNOWN_COMPANY_LOGO_ERROR:
			currentState.pendingRequests -= 1;
			currentState.deletingUnknownCompanyLogoUIDsObject = null;
			currentState.deleteUnknownCompanyLogoError = action.error;
			break;

		/* === GET === */
		case GET_UNKNOWN_COMPANY_LOGOS:
			currentState.pendingRequests += 1;
			break;

		case GOT_UNKNOWN_COMPANY_LOGOS_SUCCESS:
			currentState.pendingRequests -= 1;
			currentState.unknownCompanies = action.unknownCompanies;
			currentState.unknownCompaniesList = action.unknownCompaniesList;
			currentState.getUnknownCompanyLogoError = null;
			break;

		case GOT_UNKNOWN_COMPANY_LOGOS_ERROR:
			currentState.pendingRequests -= 1;
			currentState.unknownCompanies = null;
			currentState.unknownCompaniesList = [];
			currentState.getUnknownCompanyLogoError = action.error;
			break;


		/* === DEFAULT === */
		default:
			return state;
	}

	// make sure the pending requests are never negative
	if (currentState.pendingRequests < 0) {
		currentState.pendingRequests = 0;
	}

	return currentState;
}



function getDefaultState(): AvailableCompanyLogosState {
	return {
		pendingRequests: 0,

		addCompanyLogoError: null,
		deletingCompanyLogoError: null,
		getCompanyLogosError: null,
		companyLogos: null,
		companyLogosList: [],
		companyNameKeys: null,

		addUnknownCompanyLogoError: null,
		deleteUnknownCompanyLogoError: null,
		getUnknownCompanyLogoError: null,
		unknownCompanies: null,
		unknownCompaniesList: [],
		deletingUnknownCompanyLogoUIDsObject: null,

		isDeletingCompanyLogoUID: null,
	}
}

import {
	SET_APP_ROUTE,

	SET_APP_ONLINE,
	SET_APP_OFFLINE,

	ADD_PENDING_REQUESTS,
	REMOVE_PENDING_REQUESTS,

	LOG_IN_WITH_GOOGLE,
	LOGGED_IN_WITH_GOOGLE_SUCCESS,
	LOGGED_IN_WITH_GOOGLE_ERROR,

	LOG_OUT,
	LOGGED_OUT_SUCCESS,
	LOGGED_OUT_ERROR,

	SET_USER_AUTH_DATA,

	SET_FIREBASE_APP_PARAMETERS,

	AppState,
} from './AppDefinitions';
import {getDeviceUID} from "./shared/ts/generalTools";



export default function appReducer(state: AppState = getDefaultState(), action: any): AppState {
	let currentState: AppState = Object.assign({}, state);


	switch (action.type) {
		/* ============== NETWORK ============== */
		case SET_APP_ONLINE:
			currentState.isAppOnline = true;
			break;

		case SET_APP_OFFLINE:
			currentState.isAppOnline = false;
			break;


		/* ============== PENDING REQUESTS ============== */
		case ADD_PENDING_REQUESTS:
			currentState.pendingRequests += action.requestsCount;
			break;

		case REMOVE_PENDING_REQUESTS:
			currentState.pendingRequests -= action.requestsCount;
			break;


		/* ============== SET APP ROUTE ============== */
		case SET_APP_ROUTE:
			currentState.currentRoute = action.route;
			currentState.routesHistory.push(action.route);
			break;


		/* ============== LOGIN WITH GOOGLE ============== */
		case LOG_IN_WITH_GOOGLE:
			currentState.pendingRequests += 1;
			currentState.authUserError = null;
			break;

		case LOGGED_IN_WITH_GOOGLE_SUCCESS:
			currentState.pendingRequests -= 1;
			currentState.authUserError = null;
			break;

		case LOGGED_IN_WITH_GOOGLE_ERROR:
			currentState.pendingRequests -= 1;
			currentState.authUserError = action.authUserError;
			break;


		/* ============== LOGOUT ============== */
		case LOG_OUT:
			currentState.pendingRequests += 1;
			currentState.authUserError = null;
			break;

		case LOGGED_OUT_SUCCESS:
			currentState.pendingRequests -= 1;
			currentState.authUserError = null;
			break;

		case LOGGED_OUT_ERROR:
			currentState.pendingRequests -= 1;
			currentState.authUserError = action.authUserError;
			break;


		/* ============== SET USER AUTH DATA ============== */
		case SET_USER_AUTH_DATA:
			currentState.authUserData = action.authUserData;
			currentState.wasInitialAuthChecked = true;
			break;


		/* ============== FIREBASE APP PARAMETERS ============== */
		case SET_FIREBASE_APP_PARAMETERS:
			currentState.firebaseParameters = action.parameters;
			break;


		default:
			return state;
	}

	// make sure pendingRequests is not less than 0
	if (currentState.pendingRequests < 0) {
		currentState.pendingRequests = 0;
	}

	return currentState;
}



function getDefaultState(): AppState {
	return {
		isAppOnline: navigator.onLine,
		deviceId: getDeviceUID(),
		isDev: isDev(),
		isStaging: isStaging(),
		isProd: isProd(),
		firebaseParameters: null,
		isWebAppInstalled: (window.location.hash.indexOf('#installed') !== -1),
		pendingRequests: 0,
		currentRoute: null,
		routesHistory: [],
		wasInitialAuthChecked: false,
		authUserData: null,
		authUserError: null,
	};
}

function isDev(): boolean {
	return (window.location.href.indexOf('localhost') !== -1);
}

function isStaging(): boolean {
	return (window.location.href.indexOf('painless-passwords-local.web.app') !== -1 || window.location.href.indexOf('painless-passwords-local.firebaseapp.com') !== -1);
}

function isProd(): boolean {
	return (window.location.href.indexOf('painless-passwords-prod.web.app') !== -1 || window.location.href.indexOf('painless-passwords-prod.firebaseapp.com') !== -1 || window.location.href.indexOf('painlesspasswords.com') !== -1);
}

import {ProtectedMasterKey} from "easy-web-crypto";



/* ============================================= ACTION NAMES ============================================= */
export const GET_LOGGED_USER_DATA: string = '[AUTH] GET_LOGGED_USER_DATA';
export const GOT_LOGGED_USER_DATA_SUCCESS: string = '[AUTH] GOT_LOGGED_USER_DATA_SUCCESS';
export const GOT_LOGGED_USER_DATA_ERROR: string = '[AUTH] GOT_LOGGED_USER_DATA_ERROR';

export const SET_LOGGED_USER_DATA: string = '[AUTH] SET_LOGGED_USER_DATA';
export const SET_LOGGED_USER_DATA_SUCCESS: string = '[AUTH] SET_LOGGED_USER_DATA_SUCCESS';
export const SET_LOGGED_USER_DATA_ERROR: string = '[AUTH] SET_LOGGED_USER_DATA_ERROR';

export const GET_LOGGED_USER_DEVICES: string = '[AUTH] GET_LOGGED_USER_DEVICES';
export const GOT_LOGGED_USER_DEVICES_SUCCESS: string = '[AUTH] GOT_LOGGED_USER_DEVICES_SUCCESS';
export const GOT_LOGGED_USER_DEVICES_ERROR: string = '[AUTH] GOT_LOGGED_USER_DEVICES_ERROR';

export const ADD_LOGGED_USER_DEVICE: string = '[AUTH] ADD_LOGGED_USER_DEVICE';
export const ADDED_LOGGED_USER_DEVICE_SUCCESS: string = '[AUTH] ADDED_LOGGED_USER_DEVICE_SUCCESS';
export const ADDED_LOGGED_USER_DEVICE_ERROR: string = '[AUTH] ADDED_LOGGED_USER_DEVICE_ERROR';

export const UPDATE_LOGGED_USER_DEVICE: string = '[AUTH] UPDATE_LOGGED_USER_DEVICE';
export const UPDATED_LOGGED_USER_DEVICE_SUCCESS: string = '[AUTH] UPDATED_LOGGED_USER_DEVICE_SUCCESS';
export const UPDATED_LOGGED_USER_DEVICE_ERROR: string = '[AUTH] UPDATED_LOGGED_USER_DEVICE_ERROR';

export const SET_LOGGED_USER_PUBLIC_INFORMATION: string = '[AUTH] SET_LOGGED_USER_PUBLIC_INFORMATION';
export const SET_LOGGED_USER_PUBLIC_INFORMATION_SUCCESS: string = '[AUTH] SET_LOGGED_USER_PUBLIC_INFORMATION_SUCCESS';
export const SET_LOGGED_USER_PUBLIC_INFORMATION_ERROR: string = '[AUTH] SET_LOGGED_USER_PUBLIC_INFORMATION_ERROR';



/* ============================================= ANALYTICS EVENT NAMES ============================================= */
export const web_app_installed: string = 'web_app_installed';



/* ============================================= GENERAL ============================================= */
export interface PublicUser {
	uid?: string;
	displayName?: string;
	photoURL?: string;
}



/* ============================================= STATE ============================================= */
export interface LoggedUserData {
	uid?: string;
	isAdmin?: boolean;
	displayName?: string;
	email?: string;
	emailVerified?: boolean;
	phoneNumber?: string;
	photoURL?: string;

	protectedMasterKey?: ProtectedMasterKey;
	decryptedMasterKey?: CryptoKey;
}

export interface LoggedUserDataState {
	pendingRequests: number;

	data: LoggedUserData | null;
	loggedUserDataError: string | null;

	isInitialDevicesRequestDone: boolean;
	devices: LoggedUserDevicesObject | null;
	devicesError: string | null;
}

export interface LoggedUserDevicesObject {
	[deviceUID: string]: LoggedUserDevice;
}

export interface LoggedUserDevice {
	uid: string;
	name: string | null;

	isWebApp: boolean;
	isWebAppInstalled: boolean;
	userAgent: string | null;
}

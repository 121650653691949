import {
	ADD_PENDING_REQUEST,
	REMOVE_PENDING_REQUEST,

	ADD_CREDIT_CARD_ISSUER,
	ADDED_CREDIT_CARD_ISSUER_SUCCESS,
	ADDED_CREDIT_CARD_ISSUER_ERROR,

	GET_AVAILABLE_CREDIT_CARD_ISSUERS,
	GOT_AVAILABLE_CREDIT_CARD_ISSUERS_SUCCESS,
	GOT_AVAILABLE_CREDIT_CARD_ISSUERS_ERROR,

	DELETE_AVAILABLE_CREDIT_CARD_ISSUER,
	DELETED_AVAILABLE_CREDIT_CARD_ISSUERS_SUCCESS,
	DELETED_AVAILABLE_CREDIT_CARD_ISSUERS_ERROR,

	AvailableCreditCardIssuersState,
} from './AvailableCreditCardIssuersDefinitions';



export default function AvailableCreditCardIssuersReducer(state: AvailableCreditCardIssuersState = getDefaultState(), action: any): AvailableCreditCardIssuersState {
	const currentState: AvailableCreditCardIssuersState = Object.assign({}, state);


	switch(action.type) {
		/* === GENERAL === */
		case ADD_PENDING_REQUEST:
			currentState.pendingRequests += 1;
			break;

		case REMOVE_PENDING_REQUEST:
			currentState.pendingRequests -= 1;
			break;


		/* === ADD === */
		case ADD_CREDIT_CARD_ISSUER:
			currentState.pendingRequests += 1;
			break;

		case ADDED_CREDIT_CARD_ISSUER_SUCCESS:
			currentState.pendingRequests -= 1;
			currentState.addCardIssuerError = null;
			break;

		case ADDED_CREDIT_CARD_ISSUER_ERROR:
			currentState.pendingRequests -= 1;
			currentState.addCardIssuerError = action.error;
			break;


		/* === GET === */
		case GET_AVAILABLE_CREDIT_CARD_ISSUERS:
			currentState.pendingRequests += 1;
			break;

		case GOT_AVAILABLE_CREDIT_CARD_ISSUERS_SUCCESS:
			currentState.pendingRequests -= 1;
			currentState.issuers = action.availableIssuers;
			currentState.issuersList = action.availableIssuersList;
			currentState.issuersNamesKeyMap = action.availableIssuersNameKeys;
			currentState.getCardIssuersError = null;
			break;

		case GOT_AVAILABLE_CREDIT_CARD_ISSUERS_ERROR:
			currentState.pendingRequests -= 1;
			currentState.issuers = null;
			currentState.issuersList = [];
			currentState.issuersNamesKeyMap = null;
			currentState.getCardIssuersError = action.error;
			break;


		/* === DELETE === */
		case DELETE_AVAILABLE_CREDIT_CARD_ISSUER:
			currentState.pendingRequests += 1;
			break;

		case DELETED_AVAILABLE_CREDIT_CARD_ISSUERS_SUCCESS:
			currentState.pendingRequests -= 1;
			currentState.deleteCardIssuerError = null;
			break;

		case DELETED_AVAILABLE_CREDIT_CARD_ISSUERS_ERROR:
			currentState.pendingRequests -= 1;
			currentState.deleteCardIssuerError = action.error;
			break;


		default:
			return state;
	}

	// make sure pending requests never goes below 0
	if (currentState.pendingRequests < 0) {
		currentState.pendingRequests = 0;
	}

	return currentState;
}



function getDefaultState(): AvailableCreditCardIssuersState {
	return {
		pendingRequests: 0,

		issuers: null,
		issuersList: [],
		issuersNamesKeyMap: null,

		addCardIssuerError: null,
		getCardIssuersError: null,
		deleteCardIssuerError: null,
	};
}

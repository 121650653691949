import firebase from 'firebase/app';
import CreditCard from "./classes/CreditCard";



/* ============================================= ACTION NAMES ============================================= */
/* === USER CREDIT CARDS === */
export const GET_USER_CREDIT_CARDS: string = '[CREDIT_CARDS] GET_USER_CREDIT_CARDS';
export const GOT_USER_CREDIT_CARDS_SUCCESS: string = '[CREDIT_CARDS] GOT_USER_CREDIT_CARDS_SUCCESS';
export const GOT_USER_CREDIT_CARDS_ERROR: string = '[CREDIT_CARDS] GOT_USER_CREDIT_CARDS_ERROR';

export const ADD_USER_CREDIT_CARD: string = '[CREDIT_CARDS] ADD_USER_CREDIT_CARD';
export const ADDED_USER_CREDIT_CARD_SUCCESS: string = '[CREDIT_CARDS] ADDED_USER_CREDIT_CARD_SUCCESS';
export const ADDED_USER_CREDIT_CARD_ERROR: string = '[CREDIT_CARDS] ADDED_USER_CREDIT_CARD_ERROR';

export const UPDATE_USER_CREDIT_CARD: string = '[CREDIT_CARDS] UPDATE_USER_CREDIT_CARD';
export const UPDATED_USER_CREDIT_CARD_SUCCESS: string = '[CREDIT_CARDS] UPDATED_USER_CREDIT_CARD_SUCCESS';
export const UPDATED_USER_CREDIT_CARD_ERROR: string = '[CREDIT_CARDS] UPDATED_USER_CREDIT_CARD_ERROR';

export const DELETE_USER_CREDIT_CARD: string = '[CREDIT_CARDS] DELETE_USER_CREDIT_CARD';
export const DELETED_USER_CREDIT_CARD_SUCCESS: string = '[CREDIT_CARDS] DELETED_USER_CREDIT_CARD_SUCCESS';
export const DELETED_USER_CREDIT_CARD_ERROR: string = '[CREDIT_CARDS] DELETED_USER_CREDIT_CARD_ERROR';



/* ============================================= GENERAL ============================================= */
export interface CreditCardUpdate {
	updatedByUserUID: string | null;
	updatedByUser: string | null;
	createdByUserImage: string | null;
	updatedOnDate: Date | null;
	updatedOnTimestamp: firebase.firestore.Timestamp | null
	changes: Partial<CreditCard>;
}



/* ============================================= ANALYTICS EVENT NAMES ============================================= */
export const user_card_added: string = 'user_card_added';
export const user_card_deleted: string = 'user_card_deleted';



/* ============================================= STATE ============================================= */
export const usersCreditCardsCollectionName: string = 'creditCards';

/* === USER CREDIT CARDS === */
export interface CreditCardsObject {
	[creditCardUID: string]: CreditCard;
}



/* ============================================= STATE ============================================= */
export interface CreditCardState {
	pendingRequests: number;

	creditCardsList: Array<string>;
	creditCards: CreditCardsObject | null;

	isDeletingCreditCardUID: string | null;

	error: string | null;
}

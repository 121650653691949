import {
	GET_LOGGED_USER_DATA,
	GOT_LOGGED_USER_DATA_SUCCESS,
	GOT_LOGGED_USER_DATA_ERROR,

	SET_LOGGED_USER_DATA,
	SET_LOGGED_USER_DATA_SUCCESS,
	SET_LOGGED_USER_DATA_ERROR,

	GET_LOGGED_USER_DEVICES,
	GOT_LOGGED_USER_DEVICES_SUCCESS,
	GOT_LOGGED_USER_DEVICES_ERROR,

	LoggedUserDataState,
} from './LoggedUserDataDefinitions';



export default function loggedUserDataReducer(state: LoggedUserDataState = getDefaultState(), action: any): any {
	let currentState = Object.assign({}, state);


	switch(action.type) {
		/* ============== LOGGED USER DATA ============== */
		case GET_LOGGED_USER_DATA:
			currentState.pendingRequests += 1;
			break;

		case GOT_LOGGED_USER_DATA_SUCCESS:
			currentState.pendingRequests -= 1;
			currentState.data = action.userData;
			currentState.loggedUserDataError = null;
			break;

		case GOT_LOGGED_USER_DATA_ERROR:
			currentState.pendingRequests -= 1;
			currentState.data = null;
			currentState.loggedUserDataError = action.error;
			break;


		/* ============== SET USER DATA ============== */
		case SET_LOGGED_USER_DATA:
			currentState.pendingRequests += 1;
			break;

		case SET_LOGGED_USER_DATA_SUCCESS:
			currentState.pendingRequests -= 1;
			break;

		case SET_LOGGED_USER_DATA_ERROR:
			currentState.pendingRequests -= 1;
			break;


		/* ============== GET DEVICES ============== */
		case GET_LOGGED_USER_DEVICES:
			currentState.pendingRequests += 1;
			break;

		case GOT_LOGGED_USER_DEVICES_SUCCESS:
			currentState.pendingRequests -= 1;
			currentState.devices = action.devices;
			currentState.isInitialDevicesRequestDone = true;
			currentState.devicesError = null;
			break;

		case GOT_LOGGED_USER_DEVICES_ERROR:
			currentState.pendingRequests -= 1;
			currentState.devices = null;
			currentState.isInitialDevicesRequestDone = true;
			currentState.devicesError = action.error;
			break;


		default:
			return state;
	}

	// make sure pendingRequests is not less than 0
	if (currentState.pendingRequests < 0) {
		currentState.pendingRequests = 0;
	}

	return currentState;
}


function getDefaultState(): LoggedUserDataState {
	return {
		pendingRequests: 0,
		data: null,
		loggedUserDataError: null,

		isInitialDevicesRequestDone: false,
		devices: null,
		devicesError: null,
	}
}

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import './index.css';

import reportWebVitals from './reportWebVitals';

// import {createMuiTheme, ThemeProvider} from "@material-ui/core/styles";
import {ThemeProvider} from "@material-ui/core/styles";
import {unstable_createMuiStrictModeTheme as createMuiTheme} from '@material-ui/core';

import {blueGrey, deepOrange} from '@material-ui/core/colors'

import {Provider} from 'react-redux';
import {applyMiddleware, compose, createStore} from "redux";
import thunk from 'redux-thunk';
import reducers from './reducers';

import {BrowserRouter} from "react-router-dom";

import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider} from "@material-ui/pickers";



// @ts-ignore
let devTools = window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (func:any) => func;


// @ts-ignore
if (process.env.NODE_ENV === 'prod' || process.env.NODE_ENV === 'production') {
	devTools = (func:any) => func;
}

const store = createStore(
	reducers,
	compose(
		applyMiddleware(
			thunk,
		),
		devTools
	),
);

const appTheme = createMuiTheme({
	palette: {
		primary: {
			main: blueGrey[500],
		},
		secondary: {
			main: deepOrange[500],
		},
	},
});


ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<Provider store={store}>
				<ThemeProvider theme={appTheme}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<App/>
					</MuiPickersUtilsProvider>
				</ThemeProvider>
			</Provider>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import firebase from 'firebase/app';
import 'firebase/auth';
import {
	SET_APP_ROUTE,
	AppRoute,

	SET_PAGE_VIEW,

	ADD_PENDING_REQUESTS,
	REMOVE_PENDING_REQUESTS,

	SET_APP_ONLINE,
	SET_APP_OFFLINE,

	LOG_IN_WITH_GOOGLE,
	LOGGED_IN_WITH_GOOGLE_SUCCESS,
	LOGGED_IN_WITH_GOOGLE_ERROR,

	LOG_OUT,
	LOGGED_OUT_SUCCESS,
	LOGGED_OUT_ERROR,

	SET_USER_AUTH_DATA,

	SET_FIREBASE_APP_PARAMETERS,

	page_view,
	log_in_with_google,
	log_out,

	FirebaseAppParameters,
} from './AppDefinitions';
import {logEvent} from "./shared/ts/firebaseAnalytics";
import {Dispatch} from "redux";



let firebaseAuthRef: firebase.auth.Auth | null = null;


function setFirebaseAuth() {
	// make sure auth ref is set}
	if (firebaseAuthRef === void 0 || firebaseAuthRef === null) {
		firebaseAuthRef = firebase.auth();
	}
}


export function setAppRoute(route: AppRoute): Function {
	return (dispatch: any, getState: Function) => {
		dispatch({
			type: SET_APP_ROUTE,
			route: route,
		})
	}
}

export function setFirebaseAppParameters(firebaseParameters: FirebaseAppParameters): Function {
	return (dispatch: any, getState: Function) => {
		dispatch({
			type: SET_FIREBASE_APP_PARAMETERS,
			parameters: firebaseParameters
		});
	}
}

export function addPendingRequests(requestsCount: number = 1): Function {
	return (dispatch: any, getState: Function) => {
		dispatch({
			type: ADD_PENDING_REQUESTS,
			requestsCount: requestsCount,
		});
	};
}

export function removePendingRequests(requestsCount: number = 1): Function {
	return (dispatch: any, getState: Function) => {
		dispatch({
			type: REMOVE_PENDING_REQUESTS,
			requestsCount: requestsCount,
		});
	}
}

export function setAppOnline(): Function {
	return (dispatch: Dispatch, getState: Function) => {
		dispatch({
			type: SET_APP_ONLINE,
		});
	}
}

export function setAppOffline(): Function {
	return (dispatch: Dispatch, getState: Function) => {
		dispatch({
			type: SET_APP_OFFLINE,
		});
	}
}

export function setPageView(pathname: string | null = null, parameters: any = null): Function {
	// set firebase refs
	setFirebaseAuth();

	return (dispatch: any, getState: any) => {
		// fire action
		dispatch({
			type: SET_PAGE_VIEW,
			pathName: pathname,
			parameters: parameters,
		});

		// SET_PAGE_VIEW
		logEvent(
			page_view,
			Object.assign({}, parameters, {pathName: pathname})
		);
	}
}

export function loginWithGoogle(): Function {
	// set firebase refs
	setFirebaseAuth();

	return (dispatch: any, getState: Function) => {
		let googleProvider = new firebase.auth.GoogleAuthProvider();


		googleProvider.addScope('https://www.googleapis.com/auth/userinfo.email');

		// ignore if firebaseAuthRef is null
		if (firebaseAuthRef === null) {
			return null;
		}

		// fire action
		dispatch({
			type: LOG_IN_WITH_GOOGLE,
		});

		firebaseAuthRef
			.signInWithPopup(googleProvider)
			.then(
				(loggedUser: firebase.auth.UserCredential) => {
					// validate loggedUser
					if (!loggedUser || !loggedUser.user) {
						// fire error action
						dispatch({
							type: LOGGED_IN_WITH_GOOGLE_ERROR,
							authUserError: 'Google did not return any data from the user.',
						});

						return;
					}

					// fire success action
					dispatch({
						type: LOGGED_IN_WITH_GOOGLE_SUCCESS,
					});

					// set login with google analytics event
					logEvent(
						log_in_with_google,
						loggedUser
					);
				})
				.catch(
					(error: firebase.auth.Error) => {
						// fire error action
						dispatch({
							type: LOGGED_IN_WITH_GOOGLE_ERROR,
							authUserError: error,
						});
					}
				);
	}
}

export function logout(): Function {
	// set firebase refs
	setFirebaseAuth();

	return (dispatch: any, getState: Function) => {
		// ignore if firebaseAuthRef is null
		if (firebaseAuthRef === null) {
			return null;
		}

		// fire action
		dispatch({
			type: LOG_OUT,
		});

		firebaseAuthRef
			.signOut ()
			.then(
				() => {
					// fire success action
					dispatch({
						type: LOGGED_OUT_SUCCESS,
					});

					// set login with google analytics event
					logEvent(
						log_out
					);
				})
				.catch(
					(error: firebase.auth.Error) => {
						// fire error action
						dispatch({
							type: LOGGED_OUT_ERROR,
							authUserError: error,
						});
					}
				);
	}
}

export function setUserAuthData(userAuthData: any): Function {
	return (dispatch: any, getState: Function) => {
		dispatch({
			type: SET_USER_AUTH_DATA,
			authUserData: userAuthData,
		});
	}
}

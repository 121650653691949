import {combineReducers} from "redux";
import AppReducer from "./AppReducer";
import AuthReducer from "./Auth/LoggedUserDataReducer";

import PasswordsReducer from "./Auth/Passwords/PasswordsReducer";
import CreditCardsReducer from "./Auth/CreditCards/CreditCardsReducer";

import AvailableCompanyLogosReducer from "./Auth/AvailableCompanyLogos/AvailableCompanyLogosReducer";
import AvailableCreditCardIssuersReducer from "./Auth/AvailableCreditCardIssuer/AvailableCreditCardIssuersReducer";



export default combineReducers({
	app: AppReducer,
	loggedUserData: AuthReducer,

	passwords: PasswordsReducer,
	creditCards: CreditCardsReducer,

	availableCompanyLogos: AvailableCompanyLogosReducer,
	availableCreditCardIssuers: AvailableCreditCardIssuersReducer,
})
